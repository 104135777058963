@charset "utf-8";
/* ==============================
  変数を定義
================================= */
@use "sass:map";

/* color */
$palette-def: #0D2535;
$palette-primary: #F0AD4E;
$palette-secondary: #1E78BE;

$color-def: $palette-def;


/* btn */
$btn-colors: (
  "primary": $palette-primary,
  "secondary": $palette-secondary,
);
$btn-size: (
  "small": 200px,
  "medium": 400px,
  "large": 600px,
);


/* media query */
$sp: 375px;
$ipad: 768px;
$pc: 1060px;
