@charset "UTF-8";
/* =========================================================
 Base
========================================================= */
/* ==============================
  base配下のscssを読み込み
================================= */
/* ==============================
  変数を定義
================================= */
/* color */
/* btn */
/* media query */
/* ==============================
  base配下のscssを読み込み
================================= */
/* ==============================
  base/mixin
================================= */
/* ==============================
  base配下のscssを読み込み
================================= */
/* ==============================
  base/base
================================= */
/* =========================================================
 Layout
========================================================= */
/* =========================================================
 Base
========================================================= */
/* ==============================
  layout/footer
================================= */
.def-container .footer-btn-area {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: #DEE2E6;
  padding: 32px 0;
}

@media (max-width: 768px) {
  .def-container .footer-btn-area {
    width: 100%;
  }
}
/* =========================================================
 Base
========================================================= */
/* ==============================
  layout/header
================================= */
/* =========================================================
 Base
========================================================= */
/* ==============================
  layout/layout
================================= */
.l-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 80px;
}
@media (max-width: 1060px) {
  .l-header {
    height: 60px;
  }
}
.l-main {
  margin-top: 80px;
}
@media (max-width: 1060px) {
  .l-main {
    margin-top: 60px;
  }
}
.l-main-inner {
  padding-top: 68px;
}
@media (max-width: 768px) {
  .l-main-inner {
    padding-top: 50px;
  }
}

/* =========================================================
 Module 使い回しできるパーツのスタイル
========================================================= */
/* =========================================================
 Base
========================================================= */
/* ==============================
  module/btn
================================= */
.def-container .btn {
  transition: 0.4s;
}
.def-container .btn:hover {
  opacity: 0.65;
}
.def-container .btn-wrap {
  margin-top: 26px;
  text-align: center;
}
.def-container .btn-second {
  border: 1px solid #1EB6C3;
  color: #1EB6C3;
  background-color: #fff;
  font-size: 16px;
  padding: 7px 13px;
}
.def-container .btn-tertiary {
  max-width: 300px;
  width: 100%;
  height: 46px;
  border-radius: 4px;
  background-color: #1EB6C3;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  opacity: 1;
}
.def-container .btn-quaternary {
  background-color: transparent;
  color: #1EB6C3;
  font-size: 12px;
  font-weight: normal;
}
.def-container .btn-search {
  max-width: 315px;
  width: 100%;
  height: 44px;
  font-size: 16px;
  color: #fff;
  background-color: #1EB6C3;
  margin: 26px auto 0;
}
.def-container .btn-none {
  background-color: #9EA2A5;
  font-weight: 500;
}
.def-container .btn-short {
  max-width: 170px;
  width: 100%;
  line-height: 1;
  height: 44px;
}
.def-container .btn-shorter {
  max-width: 138px;
  height: 38px;
}
.def-container .btn-back {
  color: #212529;
  font-size: 16px;
  opacity: 1;
  margin-top: 20px;
  font-weight: 500;
  padding: 0;
  border: none;
}
.def-container .badge {
  font-size: 12px;
}

@media (max-width: 768px) {
  .def-container .btn:hover {
    opacity: 1;
  }
  .def-container .btn-search {
    margin-top: 20px;
  }
}
/* =========================================================
 Base
========================================================= */
/* ==============================
  module/form
================================= */
.def-container .card-container {
  background-color: #ECF0F2 !important;
  box-shadow: none;
  margin-bottom: 0;
}
.def-container .card-container-cancellation {
  background-color: transparent !important;
}
.def-container .card-container.white {
  background-color: #fff !important;
}
.def-container .card-accordion-container {
  background-color: #fff !important;
  box-shadow: none;
  margin-bottom: 0;
  border: 1px solid #CED4DA;
  margin-top: 24px;
}
.def-container .card-accordion-area {
  background-color: #fff !important;
  box-shadow: none;
  margin-bottom: 0;
  border: 1px solid #CED4DA;
  margin: 20px 36px 0;
}
.def-container .card-accordion-area .card-header {
  border-bottom: 1px solid #CED4DA;
}
.def-container .card-accordion-area .zeus-card-title span {
  color: #495057;
  padding-left: 20px;
}
.def-container .card-accordion-area .card-body {
  padding: 20px 20px 26px;
  background-color: #fff;
}
.def-container .card-wrap {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #CED4DA;
}
.def-container .card-wrap.no-border {
  border: none;
  border-radius: 0;
}
.def-container .card-header {
  border-bottom: none;
  box-shadow: 0 3px 6px rgba(206, 212, 218, 0.1607843137);
}
.def-container .card-title {
  font-size: 20px;
  font-weight: 600;
}
.def-container .card-title.small {
  font-size: 18px;
}
.def-container .card-inner {
  padding: 20px 20px 26px;
  box-shadow: 0 3px 6px rgba(206, 212, 218, 0.1607843137);
}
.def-container .card-inner.no-pd {
  padding: 0;
}
.def-container .card-inner-header {
  width: 100%;
  display: inline-block;
  padding: 13px 0 13px 20px;
  border-bottom: 1px solid #CED4DA;
}
.def-container .card-inner-header.gray {
  background-color: #F8F9FB;
}
.def-container .card-space {
  margin-top: 24px;
}
.def-container .card-link-txt {
  display: inline-block;
  margin-top: 26px;
}
.def-container .card-contents-wrap td span {
  display: none;
}
.def-container .card .acco-inner-acco {
  box-shadow: none;
  border: 1px solid #CED4DA;
}
.def-container .card .acco-inner-acco.collapsed-card {
  border-bottom: none;
}
.def-container .card .acco-inner-acco .card-header {
  box-shadow: none;
  height: 44px;
  line-height: 1;
}
.def-container .card .acco-inner-acco .card-header .zeus-card-title {
  font-size: 16px;
}
.def-container .collapsed-card {
  border-bottom: none;
}
.def-container .card-wrap .card-wrap .card-inner-header .zeus-card-title {
  font-size: 16px;
}

@media (max-width: 768px) {
  .def-container .card-container {
    box-shadow: none;
    margin-bottom: 0;
  }
  .def-container .card-wrap {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #CED4DA;
  }
  .def-container .card-accordion-area {
    background-color: #fff !important;
    box-shadow: none;
    margin-bottom: 0;
    border: 1px solid #CED4DA;
    margin: 20px 10px 0;
  }
  .def-container .card-accordion-area .card-header {
    padding: 14px 20px;
  }
  .def-container .card-accordion-area .zeus-card-title {
    font-size: 16px;
  }
  .def-container .card-accordion-area .zeus-card-title span {
    display: block;
    font-size: 20px;
    padding-left: 0;
  }
  .def-container .card-header {
    border-bottom: none;
    padding-top: 0;
    padding-left: 10px;
  }
  .def-container .card-title {
    font-size: 20px;
    font-weight: 600;
  }
  .def-container .card-inner {
    padding: 20px 20px 26px;
  }
  .def-container .card-inner-header {
    width: 100%;
    display: inline-block;
    padding: 13px 0 13px 20px;
    border-bottom: 1px solid #CED4DA;
  }
  .def-container .card-space {
    margin-top: 24px;
  }
  .def-container .card-link-txt {
    display: inline-block;
    margin-top: 26px;
  }
  .def-container .card-contents-wrap td span {
    display: inline-block;
  }
}
/* Chart.js */
@keyframes chartjs-render-animation {
  from {
    opacity: 0.99;
  }
  to {
    opacity: 1;
  }
}
.chartjs-render-monitor {
  animation: chartjs-render-animation 1ms;
}

.chartjs-size-monitor,
.chartjs-size-monitor-expand,
.chartjs-size-monitor-shrink {
  position: absolute;
  direction: ltr;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  visibility: hidden;
  z-index: -1;
}

.chartjs-size-monitor-expand > div {
  position: absolute;
  width: 1000000px;
  height: 1000000px;
  left: 0;
  top: 0;
}

.chartjs-size-monitor-shrink > div {
  position: absolute;
  width: 200%;
  height: 200%;
  left: 0;
  top: 0;
}

/* =========================================================
 Base
========================================================= */
/* ==============================
  module/form
================================= */
div[name=step-two] {
  display: block !important;
}

/* =========================================================
 Base
========================================================= */
/* ==============================
  module/form
================================= */
.def-container .form-group {
  margin-bottom: 0;
  margin-top: 0px;
}
.def-container .form-group + .form-group {
  margin-top: 20px;
}
.def-container .form-group + div {
  margin-top: 20px;
}
.def-container .form-group.form-btn-group {
  margin-top: 26px;
}
.def-container .form-check {
  padding-top: 0px;
  padding-bottom: 0;
  padding-left: 30px;
  margin-top: 10px;
}
.def-container .form-check-label {
  position: relative;
}
.def-container .form-check-label span::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #707070;
  border-radius: 999px;
}
.def-container .form-check-label dl::before {
  content: "";
  position: absolute;
  top: 4px;
  left: -30px;
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #707070;
  border-radius: 999px;
}
.def-container .form-check-input.require.check + span::before {
  background-color: #fff;
}
.def-container .form-control {
  appearance: none;
  margin-top: 4px;
}
.def-container .form-wrap {
  margin-top: 4px;
}
.def-container .form-select-wrap {
  position: relative;
  margin-top: 4px;
}
.def-container .form-select-wrap::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%) rotate(45deg);
  display: block;
  width: 6px;
  height: 6px;
  border-bottom: 1px solid #4F5054;
  border-right: 1px solid #4F5054;
}
.def-container .form-area {
  margin-top: 0;
}
.def-container .form-area + .form-area {
  margin-top: 20px;
}
.def-container .form-serch {
  background-color: #F3FAFB;
}
.def-container .input-area {
  margin-top: 4px;
}
.def-container input[type=text]:valid, .def-container input[type=tel]:valid {
  background-color: #fff;
}
.def-container input[type=text]:invalid, .def-container input[type=tel]:invalid {
  background-color: #F3FAFB;
}
.def-container input[type=radio] {
  appearance: none;
  transform: scale(1);
}
.def-container input[type=radio].require + span::before {
  background-color: #F3FAFB;
}
.def-container input[type=radio]:checked + span::before {
  border-color: #007BFF;
}
.def-container input[type=radio]:checked + span::after {
  content: "";
  position: absolute;
  top: 50%;
  left: -25px;
  transform: translateY(-50%);
  display: block;
  width: 10px;
  height: 10px;
  background-color: #007BFF;
  border-radius: 999px;
}
.def-container input[type=radio]:checked + dl::before {
  border-color: #007BFF;
}
.def-container input[type=radio]:checked + dl::after {
  content: "";
  position: absolute;
  top: 9px;
  left: -25px;
  display: block;
  width: 10px;
  height: 10px;
  background-color: #007BFF;
  border-radius: 999px;
}
.def-container select:valid {
  background-color: #fff;
}
.def-container select:invalid {
  background-color: #F3FAFB;
}
.def-container .auto-select-input {
  max-width: 355px;
  position: relative;
}
.def-container .auto-select-input::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%) rotate(45deg);
  display: block;
  width: 6px;
  height: 6px;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
}
.def-container .auto-select-input-wrap {
  display: flex;
  flex-direction: column;
  border: none;
  margin-bottom: 30px;
}
.def-container .auto-select-input-wrap.check .modal-link-txt {
  display: block;
}
.def-container .auto-select-input-wrap.check select {
  background-color: #D6EAFF;
  border: 1px solid #007BFF;
  color: #007BFF;
}
.def-container .auto-select-input-wrap.check .auto-select-input::after {
  transform: translateY(-50%) rotate(-45deg);
  border-bottom: 1px solid #007BFF;
  border-right: 1px solid #007BFF;
}
.def-container .auto-select-input-wrap .modal-link-txt {
  display: none;
}
.def-container .auto-select-input select {
  border: none;
  background-color: transparent;
  color: #fff;
  background-color: #007BFF;
  border: 1px solid #007BFF;
  appearance: none;
  width: 100%;
  height: 32px;
  padding: 0px 20px;
  outline: none;
  border-radius: 4px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .def-container .form-check {
    padding-left: 32px;
  }
  .def-container .form-check + .form-check {
    margin-top: 10px;
  }
}
/* =========================================================
 Base
========================================================= */
/* ==============================
  module/help
================================= */
.def-container.help-container {
  padding-bottom: 40px;
}
.def-container .help-accordion-container {
  margin: 16px 37px 0;
  box-shadow: none;
}
.def-container .help-accordion-container:first-child {
  margin-top: 0px;
}
.def-container .help-accordion-container.collapsed-card .card-header {
  border-bottom: none;
}
.def-container .help-accordion-container .card-header {
  height: 55px;
  border-bottom: 1px solid #CED4DA;
  box-shadow: none;
  padding: 14px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.def-container .help-accordion-container .card-header .card-title {
  width: calc(100% - 14px);
}
.def-container .help-accordion-container .card-header.open {
  background-color: #1EB6C3;
  color: #fff;
}
.def-container .help-accordion-container .card-header.open .btn-tool {
  color: #fff;
}
.def-container .help-accordion-container .card-header.open .help-inner-title::before {
  background-image: url(../images/question_white.svg);
}
.def-container .help-accordion-container .card-body {
  padding: 16px 10px;
}
.def-container .help-accordion-inner-acco {
  border: 1px solid #CED4DA;
  border-radius: 4px;
  box-shadow: none;
  margin-bottom: 0;
  margin-top: 16px;
}
.def-container .help-accordion-inner-acco:first-of-type {
  margin-top: 0;
}
.def-container .help-accordion-inner-acco .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 14px);
  padding: 0 20px;
  background-color: #F8F9FB;
  transition: 0.2s;
}
.def-container .help-accordion-inner-acco .card-header .card-tools .btn-tool {
  margin: 0;
  padding: 0;
}
.def-container .help-accordion-inner-acco.collapsed-card .card-header {
  border-bottom: none;
  border-radius: 4px;
}
.def-container .help-inner-title {
  position: relative;
  font-size: 16px;
  margin-bottom: 0;
  width: calc(100% - 14px);
  padding-left: 38px;
}
.def-container .help-inner-title::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: inline-block;
  width: 28px;
  height: 28px;
  background-image: url(../images/question_blue.svg);
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.2s;
}
.def-container .help-inner-img {
  width: 100%;
  height: auto;
}
.def-container .help-inner-img-area {
  max-width: 315px;
  width: 100%;
  margin-bottom: 10px;
}
.def-container .help-inner-txt {
  font-size: 16px;
  margin-bottom: 0;
}
.def-container .help-inner-txt span {
  color: #FF0000;
}
.def-container .external-link {
  display: flex;
  align-items: center;
}
.def-container .external-link::after {
  content: "";
  display: inline-block;
  width: 14px;
  height: 14px;
  background-image: url(../images/external_link.svg);
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 5px;
  margin-top: 2px;
}
.def-container .download-link {
  display: flex;
  align-items: center;
}
.def-container .download-link::after {
  content: "";
  display: inline-block;
  width: 14px;
  height: 15px;
  background-image: url(../images/download_link.svg);
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 5px;
  margin-top: 2px;
}

@media (max-width: 768px) {
  .def-container .help-accordion-container {
    margin: 16px 10px 0;
  }
  .def-container .help-accordion-container:first-child {
    margin-top: 0px;
  }
  .def-container .help-accordion-container .card-header {
    padding: 15px 20px;
  }
  .def-container .help-accordion-container .card-body {
    padding: 16px 10px;
  }
  .def-container .help-accordion-inner-acco .card-header {
    padding: 10px;
    height: auto;
  }
  .def-container .help-inner-img-area {
    max-width: 70%;
    width: 100%;
    margin: 0 auto;
  }
}
/* =========================================================
 Base
========================================================= */
/* ==============================
  module/form
================================= */
.def-container {
  font-weight: 500;
}
.def-container .is-sp {
  display: none;
}
.def-container .mt8 {
  margin-top: 8px;
}
.def-container .mt16 {
  margin-top: 16px;
}
.def-container .mt20 {
  margin-top: 20px;
}
.def-container .mt24 {
  margin-top: 24px;
}
.def-container .mt40 {
  margin-top: 40px;
}
.def-container .pt8 {
  padding-top: 8px !important;
}
.def-container .pb0-26 {
  padding-bottom: 0;
}
.def-container .f-semibold {
  font-weight: 600;
}
.def-container .content {
  padding-top: 24px;
  background-color: #ECF0F2;
  margin-top: 0;
}
.def-container .content-header {
  padding: 0;
}
.def-container .construction-check-container {
  background-color: #fff !important;
  margin-top: -24px;
  border-radius: 0;
}
.def-container .construction-check-container .heading-5 {
  margin-top: 21px;
}
.def-container .construction-check-container .heading-5:first-of-type {
  margin-top: 21px;
}
.def-container .construction-check-container .card-body.assist-detail {
  padding: 10px 37px 0;
}
.def-container .construction-check-container .card-body.assist-detail > dl > .heading-5 {
  margin-top: 26px;
}
.def-container .construction-check-header {
  margin: 24px 37px 0;
}
.def-container .assist-detail dt {
  background-color: #ECF0F2;
  padding: 4px 10px;
}
.def-container .assist-detail dd {
  padding: 8px 10px 24px;
  min-height: 56px;
}
.def-container .zeus-help {
  font-size: 12px;
}
.def-container .zeus-help::before {
  margin-right: 4px;
}
.def-container .zeus-help-left {
  float: none;
  margin-left: -40px;
}
.def-container .badge {
  vertical-align: text-bottom;
  transform: translateY(-2px);
}
.def-container .badge-any {
  background-color: #DEE2E6;
  color: #6C757D;
  width: 47px;
}
.def-container .badge-success {
  padding-left: 8px;
  padding-right: 8px;
}
.def-container .badge-secondary {
  padding-left: 8px;
  padding-right: 8px;
}
.def-container .construction-history-area {
  background-color: #fff;
  padding-top: 16px;
  padding-bottom: 36px;
}
.def-container .construction-history-area .zeus-card-title {
  background-color: #fff;
  margin-bottom: 0;
  font-size: 16px;
}
.def-container .construction-history-area .card-body .card-body {
  padding: 0;
}
.def-container .alert-caution-zeus {
  background-color: #FFFBF7;
  border: 1px solid #FD7E14;
  color: #FD7E14;
  border-radius: 4px;
  padding: 16px;
  font-size: 14px;
  margin-bottom: 24px;
}
.def-container .chart-container {
  max-width: 100%;
  align-self: auto;
}
.def-container .chart-container .alert-warning-zeus {
  vertical-align: unset;
  margin: 20px;
}
.def-container .chart-container .alert-warning-zeus p {
  margin-bottom: 0;
}
.def-container .data-serch {
  display: flex;
  align-items: center;
}
.def-container .data-serch p {
  font-size: 14px;
  color: #1EB6C3;
  margin-left: 12px;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .def-container .is-sp {
    display: block;
  }
  .def-container .pb0-26 {
    padding-bottom: 26px;
  }
  .def-container .zeus-help {
    float: none;
  }
  .def-container .construction-check-container .heading-5 {
    margin-top: 40px;
  }
  .def-container .construction-check-container .heading-5:first-of-type {
    margin-top: 40px;
  }
  .def-container .construction-check-container .card-body.assist-detail {
    padding: 10px 10px 0;
  }
  .def-container .construction-check-header {
    margin: 24px 10px 0;
  }
}
.def-nav.navbar {
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
}

/* =========================================================
 Base
========================================================= */
/* ==============================
  module/form
================================= */
.def-container {
  min-height: 100vh !important;
  background-color: #ECF0F2 !important;
}
.def-container dl {
  margin-bottom: 0;
}
.def-container dd {
  margin-bottom: 0;
  font-size: 14px;
}
.def-container dt {
  font-weight: 600;
}
.def-container label {
  margin-bottom: 0;
}
.def-container .form-control {
  box-shadow: none;
}
.def-container .zeus-card-title {
  font-weight: 500;
  font-size: 18px;
}
.def-container .zeus-card-title-note {
  display: block;
  font-size: 12px;
  color: #969BA1;
  padding-top: 4px;
}
.def-container .container-fluid {
  padding: 0;
}
.def-container .content {
  padding: 24px 0 0;
  margin-top: 0px;
}
.def-container .card-inner .card-body {
  padding: 0 !important;
}
.def-container .card-body {
  padding: 16px 36px 40px;
}
.def-container .card-body.assist-detail {
  padding: 10px 20px 20px;
}
.def-container .card-body.assist-detail.short-pb {
  padding: 10px 20px 40px;
}
.def-container .alert-warning-zeus {
  color: #212529;
  background: #FFF3CD;
  border: none;
  font-size: 14px;
  font-weight: normal;
  margin-top: 12px;
  margin-bottom: 0;
  padding: 8px 16px;
  line-height: 1.5;
}
.def-container label:not(.form-check-label):not(.custom-file-label) {
  font-weight: 500;
}
.def-container .def-container label:not(.form-check-label):not(.custom-file-label) {
  font-weight: 500;
}

.btn-export-csv {
  width: 200px;
}

.full-width {
  width: 100%;
}

.small-column {
  width: 50px;
}

.medium-column {
  width: 100px;
}

.large-column {
  width: 150px;
}

.max-column {
  max-width: 160px;
}

.center-modal {
  top: 30%;
}

.center-modal .modal-dialog {
  margin-top: 30%;
}

.min-max-container {
  min-width: 900px;
  max-width: 1500px;
}

.content {
  margin-top: 34px;
}

.table-container {
  background: #f9f9f9;
  border: 1px solid #e9e9e9;
}

.min-container {
  min-width: 600px;
}

.max-container {
  max-width: 1200px;
}

.check-header {
  position: absolute;
  font-size: large;
}

.alert-warning-zeus {
  color: #1f2d3d;
  background: #fff3cd;
  border-color: #edb100;
}

.alert-warning-zeus a {
  color: #007bff;
  text-decoration: underline;
}

.alert-danger-zeus {
  color: #fff;
  background: #dc3545;
  border-color: #d32535;
}

.alert-caution-zeus a {
  color: #fff;
  text-decoration: underline;
}

.alert-caution-zeus {
  color: #fff;
  background: #F77647;
  border-color: #DE6A3F;
}

.alert-danger-zeus a {
  color: #fff;
  text-decoration: underline;
}

.alert-success-zeus {
  color: black;
  background: #d4edda;
  border-color: #23923d;
}

.alert-success-zeus a {
  color: #007bff;
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.zeus-help {
  float: right;
  color: #1EB6C3;
  margin-top: 5px;
  cursor: pointer;
}

.card {
  background-color: #fafafa !important;
}

.main-header.navbar {
  background-color: #fafafa !important;
}

.form-control {
  box-shadow: 0.3px 0.3px 0.3px darkgrey;
}

.form-control:focus {
  border-color: #1EB6C3 !important;
}

.form-check {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 40px;
}

.navbar {
  position: fixed;
  left: 0;
  right: 0;
}

.main-sidebar {
  position: fixed !important;
}

.nav-title > span {
  vertical-align: middle;
  font-size: x-large;
}

.nav-profile > .user-profile {
  pointer-events: none;
}

.badge {
  vertical-align: text-top;
  margin-left: 10px;
}

.zeus-card-title {
  font-weight: bold;
  font-size: large;
}

.main-sidebar {
  background-color: white;
}

.main-sidebar div:first-of-type > nav > ul > li > a > i {
  color: white;
}

.main-sidebar div:first-of-type > nav > ul > li > a > p {
  color: white;
}

.main-sidebar div:first-of-type {
  padding-bottom: 30px;
}

.main-sidebar div:not(:first-of-type) > nav > ul > li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.main-sidebar div:not(:first-of-type) > nav > ul > li > .active {
  background-color: #1EB6C3 !important;
}

.main-sidebar div:not(:first-of-type) > nav > ul > li > a > p {
  color: black;
}

.main-sidebar div:not(:first-of-type) > nav > ul > li > .active > p {
  color: white;
}

.zeus-brand-text {
  color: white;
  font-size: xx-large;
  padding-left: 20px;
}

.brand-link {
  border-width: 0 !important;
}

.zeus-cloud-left {
  color: white;
  font-size: 6em;
  position: absolute;
  transform: translate(-20px, 30px);
}

.zeus-cloud-right {
  color: white;
  font-size: 9em;
  position: absolute;
  transform: translate(80px, -10px);
}

.zeus-bold {
  position: absolute;
  transform: translate(170px, -110px) rotate(30deg) scale(1, 3);
  color: yellow;
  font-size: 70px;
}

.main-sidebar {
  overflow-x: hidden;
}

.main-sidebar > a {
  position: relative;
}

.btn-done {
  background-color: #1EB6C3;
  color: white;
}

.btn-done:hover {
  background-color: rgba(30, 182, 195, 0.7);
  color: white;
}

.btn-confirm {
  background-color: #F77647;
  color: white;
}

.btn-confirm:hover {
  background-color: rgba(247, 118, 71, 0.7);
  color: white;
}

.card-header > span {
  margin-right: 20px;
}

body {
  font-family: Noto Sans JP;
}

.login-form {
  border-right: 1px solid #ced4da !important;
}

.chart-container {
  max-width: 600px;
  align-self: center;
}

input[type=radio],
input[type=checkbox] {
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  -webkit-transform-origin: right;
}

.form-check-input {
  margin-top: 0.5rem !important;
  margin-left: -1.5rem !important;
}

.error-section {
  transform: translate(50%, -50%);
  top: 40%;
  position: absolute;
}

.error-zeus {
  padding-top: 10px;
}

.canvas_wrapper {
  margin: 0 auto;
}

.left-box {
  display: inline-block;
  width: 80%;
  height: 40px;
}

.right-box {
  display: inline-block;
  float: right;
  width: 20%;
  height: 40px;
  text-align: right;
}

@media screen and (max-width: 600px) {
  .content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .card-body {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .check-header > .custom-control-label {
    margin-left: 30px;
    margin-right: 20px;
  }
  .check-header {
    display: contents;
  }
  .card-header {
    display: flex;
  }
  .card-tools {
    position: absolute;
    right: 20px;
  }
  .custom-control-label::before {
    left: -30px;
  }
  .custom-control-label::after {
    left: -30px;
  }
  .min-max-container {
    min-width: 300px;
  }
}
.non-margin {
  margin: 0, 0, 0, 0;
}

[v-cloak] {
  display: none;
}

@media (max-width: 768px) {
  .elevation-4 {
    box-shadow: none !important;
  }
  .def-container .card-body {
    padding: 16px 10px 40px;
  }
  .def-container .card-body.assist-detail {
    padding: 10px 20px 20px;
  }
  .def-container .card-body.assist-detail.short-pb {
    padding: 10px 20px 40px;
  }
}
/* =========================================================
 Base
========================================================= */
.def-container table, .def-container th, .def-container td {
  border: none;
}
.def-container thead {
  background-color: #F8F9FB;
}
.def-container .noborder-table th {
  border-left: 1px solid #ECF0F2;
  border-bottom: 2px solid #ECF0F2;
  font-weight: normal;
  vertical-align: baseline;
}
.def-container .noborder-table th.short {
  width: 100px;
}
.def-container .noborder-table th:first-of-type {
  border-left: none;
}
.def-container .noborder-table td {
  border-left: 1px solid #ECF0F2;
  border-top: 1px solid #ECF0F2;
  font-size: 14px;
}
.def-container .noborder-table td:first-of-type {
  border-left: none;
  background-color: #F8F9FB;
}
.def-container .noborder-table td.short {
  width: 100px;
}
.def-container .assist-detail-table {
  width: 100%;
}
.def-container .assist-detail-table th, .def-container .assist-detail-table td {
  padding-left: 10px;
}
.def-container .assist-detail-table tr td {
  padding-top: 40px;
  padding-bottom: 0;
}
.def-container .assist-detail-table tr:nth-of-type(2) td {
  padding-top: 20px;
}
.def-container .assist-detail-table .card-header {
  font-weight: 500;
}
.def-container .assist-detail-table .card-header-wrap {
  border-bottom: 1px solid #ECF0F2;
}
.def-container .table-second {
  width: 100%;
}
.def-container .table-second th, .def-container .table-second td {
  padding-left: 10px;
}
.def-container .table-second tr td.card-body {
  padding: 20px !important;
}
.def-container .table-second tr th.card-header {
  padding: 12px 20px;
}
.def-container .table-second .card-header {
  padding: 12px 0px;
  text-align: left;
}
.def-container .table-second .card-header-wrap {
  background-color: #F8F9FB;
}
.def-container .table-second .card-body {
  line-height: 1;
  padding: 20px;
}
.def-container .table-second .card-body:last-of-type {
  text-align: right;
}
.def-container .table-second .card-body:nth-of-type(4) .form-select-wrap {
  width: 70px;
}
.def-container .table-second .card-body:nth-of-type(5) .form-select-wrap {
  width: 180px;
}
.def-container .table-second .card-body label {
  display: none;
}
.def-container .table-second .card-contents-wrap {
  border-top: 1px solid #ECF0F2;
}
.def-container .table-second .card-checkbox {
  padding: 20px;
  width: 20px;
  height: 20px;
}
.def-container .table-second .card-checkbox input {
  width: 20px;
  height: 20px;
}
.def-container .table-second .card-checkbox input[type=checkbox] {
  transform: scale(1);
}
.def-container .table-third {
  width: 100%;
}
.def-container .table-third th, .def-container .table-third td {
  padding-left: 10px;
}
.def-container .table-third tr td {
  padding-top: 40px;
  padding-bottom: 0;
}
.def-container .table-third tr:nth-of-type(2) td {
  padding-top: 20px;
}
.def-container .table-third .card-header {
  width: calc((100% - 60px) / 6);
  padding: 12px 20px 12px 0px;
  text-align: left;
  font-weight: 500;
}
.def-container .table-third .card-header-wrap {
  background-color: #F8F9FB;
}
.def-container .table-third .card-body {
  line-height: 1;
  padding: 20px 20px 20px 0 !important;
  width: calc((100% - 60px) / 6);
}
.def-container .table-third .card-contents-wrap {
  border-top: 1px solid #ECF0F2;
}
.def-container .table-third .card-checkbox {
  padding: 20px;
  width: 20px;
  height: 20px;
}
.def-container .table-third .card-checkbox input {
  width: 20px;
  height: 20px;
}
.def-container .table-third .card-checkbox input[type=checkbox] {
  transform: scale(1);
}
.def-container .table-fourth {
  border-radius: 4px;
  background-color: #fff;
  margin-top: 16px;
  margin-bottom: 0;
}
.def-container .table-fourth thead tr th {
  border: 1px solid #CED4DA;
  font-weight: 500;
}
.def-container .table-fourth tbody tr td:nth-of-type(2) {
  width: 108px;
}
.def-container .table-fourth tbody tr td:nth-of-type(2) a {
  width: 83px;
  height: 38px;
}
.def-container .table-fourth td {
  border: 1px solid #CED4DA;
  line-height: 1;
  vertical-align: unset;
}

@media (max-width: 768px) {
  .def-container .noborder-table th {
    font-size: 14px;
    padding: 12px 8px;
  }
  .def-container .noborder-table th.short {
    width: 50px;
  }
  .def-container .noborder-table td {
    padding: 12px 8px;
    font-size: 14px;
  }
  .def-container .noborder-table td.short {
    width: 50px;
    word-break: break-word;
  }
  .def-container .assist-detail-table {
    display: block;
  }
  .def-container .assist-detail-table tbody {
    display: block;
  }
  .def-container .assist-detail-table th, .def-container .assist-detail-table td {
    padding-left: 10px;
  }
  .def-container .assist-detail-table tr {
    display: block;
    border: 1px solid #DEE2E6;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
    width: 100%;
  }
  .def-container .assist-detail-table tr td {
    padding: 0 !important;
    display: flex;
    width: 100%;
    font-size: 12px;
  }
  .def-container .assist-detail-table tr td + td {
    margin-top: 8px;
  }
  .def-container .assist-detail-table tr td span {
    display: block;
    white-space: nowrap;
  }
  .def-container .assist-detail-table tr td.max-column {
    max-width: 100%;
  }
  .def-container .assist-detail-table tr:nth-of-type(2) td {
    padding-top: 0px;
  }
  .def-container .assist-detail-table .card-header-wrap {
    display: none;
  }
  .def-container .table-second {
    display: block;
  }
  .def-container .table-second tbody {
    display: block;
  }
  .def-container .table-second th, .def-container .table-second td {
    padding-left: 10px;
  }
  .def-container .table-second tr {
    display: block;
    border: 1px solid #DEE2E6;
    border-radius: 4px;
    padding: 10px;
    margin-top: 20px;
    width: 100%;
  }
  .def-container .table-second tr td {
    display: flex;
    width: 100%;
    font-size: 12px;
  }
  .def-container .table-second tr td.card-body {
    padding: 0 !important;
  }
  .def-container .table-second tr td + td {
    margin-top: 8px;
  }
  .def-container .table-second tr td span {
    display: block;
    white-space: nowrap;
  }
  .def-container .table-second tr td.max-column {
    max-width: 100%;
  }
  .def-container .table-second tr td div {
    display: flex;
  }
  .def-container .table-second tr:nth-of-type(2) {
    margin-top: 0;
  }
  .def-container .table-second tr:nth-of-type(2) td {
    padding-top: 0px;
  }
  .def-container .table-second .card-header-wrap {
    display: none;
  }
  .def-container .table-second .card-contents-wrap {
    position: relative;
    background-color: #F8F9FB;
  }
  .def-container .table-second .card-contents-wrap button {
    position: absolute;
    top: 0px;
    right: 0;
  }
  .def-container .table-second .card-contents-wrap.checkbox .card-checkbox {
    position: absolute;
    top: 14px;
    left: 10px;
  }
  .def-container .table-second .card-contents-wrap.checkbox td {
    padding-left: 30px !important;
  }
  .def-container .table-second .card-contents-wrap.checkbox td:first-of-type {
    padding-left: 0 !important;
  }
  .def-container .table-second .card-body.table-body-form {
    flex-direction: column;
  }
  .def-container .table-second .card-body label {
    display: block;
    width: 100%;
  }
  .def-container .table-second .card-body .form-control {
    height: 30px;
    font-size: 12px;
  }
  .def-container .table-second .card-body:nth-of-type(4) .form-select-wrap {
    width: 100%;
  }
  .def-container .table-second .card-body:nth-of-type(5) .form-select-wrap {
    width: 100%;
  }
  .def-container .table-second .card-body:not(:has(span)) {
    display: none;
  }
  .def-container .table-second .card-body:has(label) {
    display: block;
  }
  .def-container .table-second .card-body:has(button) {
    display: block;
    margin-top: 0;
  }
  .def-container .table-second .card-checkbox {
    padding: 0;
  }
  .def-container .table-third {
    display: block;
  }
  .def-container .table-third tbody {
    display: block;
  }
  .def-container .table-third th, .def-container .table-third td {
    padding-left: 10px;
  }
  .def-container .table-third tr {
    display: block;
    border: 1px solid #DEE2E6;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
    width: 100%;
  }
  .def-container .table-third tr td {
    padding: 0 !important;
    display: flex;
    width: auto;
    font-size: 12px;
  }
  .def-container .table-third tr td + td {
    margin-top: 8px;
  }
  .def-container .table-third tr td span {
    display: block;
    white-space: nowrap;
  }
  .def-container .table-third tr td.max-column {
    max-width: 100%;
  }
  .def-container .table-third tr td div {
    display: flex;
  }
  .def-container .table-third tr:nth-of-type(2) {
    margin-top: 0;
  }
  .def-container .table-third tr:nth-of-type(2) td {
    padding-top: 0px;
  }
  .def-container .table-third .card-header-wrap {
    display: none;
  }
  .def-container .table-third .card-contents-wrap {
    position: relative;
    padding-bottom: 0;
  }
  .def-container .table-third .card-contents-wrap button {
    position: absolute;
    top: 0;
    right: 0;
  }
  .def-container .table-third .card-body {
    margin-left: 30px;
    transform: translateY(-22px);
    width: 100%;
    padding: 0 !important;
  }
}
/* =========================================================
 Base
========================================================= */
/* ==============================
  module/form
================================= */
.def-container .heading-1 {
  padding: 0 0 0 36px;
  font-weight: 600;
  box-shadow: none;
}
.def-container .heading-2 {
  font-size: 18px;
  font-weight: 600;
}
.def-container .heading-3 {
  font-size: 16px;
}
.def-container .heading-4 {
  font-size: 20px;
  padding: 0 0 12px 0;
}
.def-container .heading-5 {
  font-size: 18px;
  font-weight: 600;
  border-left: 4px solid #1EB6C3;
  padding-left: 8px;
  margin-bottom: 17px;
  margin-top: 16px;
}
.def-container .heading-5:first-of-type {
  margin-top: 0px;
}
.def-container .heading-6 {
  display: block;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 1px solid #CED4DA;
  padding: 12px 0px;
  margin-bottom: 24px;
}
.def-container .help-txt {
  display: block;
  font-size: 12px;
  color: #1EB6C3;
  margin-top: 8px;
  margin-left: -28px;
}
.def-container .help-txt:first-of-type {
  margin-top: 0;
}
.def-container .modal-link-txt {
  display: block;
  width: 100%;
  font-size: 14px;
  color: #007BFF;
  margin-top: 12px;
}
.def-container .page-top-link {
  font-size: 14px;
  padding-left: 36px;
  margin-bottom: 8px;
  font-weight: 500;
  color: #212529;
}
.def-container .page-top-link a {
  color: #007BFF;
}
.def-container .zeus-card-form-title {
  font-size: 16px;
}
.def-container .card-title {
  float: none;
}

@media (max-width: 768px) {
  .def-container .heading-1 {
    padding: 0 0 0 10px;
  }
  .def-container .page-top-link {
    font-size: 14px;
    padding-left: 10px;
    font-weight: 500;
    color: #212529;
  }
  .def-container .page-top-link a {
    color: #007BFF;
  }
  .def-container .help-txt {
    margin-left: -32px;
  }
}
/* =========================================================
 Project ページ固有のスタイル
========================================================= */
/* =========================================================
 Base
========================================================= */
/* ==============================
  project/top
================================= */
/* =========================================================
 State
========================================================= */
/* =========================================================
 Base
========================================================= */
/* ==============================
  state/state
================================= */