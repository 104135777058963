@use "../base" as *;
@use "sass:map";

.def-container {
  table, th, td {
    border: none;
  }
  thead {
    background-color: #F8F9FB;
  }
  .noborder-table {
    th {
      border-left: 1px solid #ECF0F2;
      border-bottom: 2px solid #ECF0F2;
      font-weight: normal;
      vertical-align: baseline;
      &.short {
        width: 100px;
      }
      &:first-of-type {
        border-left: none;
      }
    }
    td {
      border-left: 1px solid #ECF0F2;
      border-top: 1px solid #ECF0F2;
      font-size: 14px;
      &:first-of-type {
        border-left: none;
        background-color: #F8F9FB;
      }
      &.short {
        width: 100px;
      }
    }
  }

  .assist-detail-table {
    width: 100%;
    th, td {
      padding-left: 10px;
    }
    tr {
      td {
        padding-top: 40px;
        padding-bottom: 0;
      }
      &:nth-of-type(2){
        td {
          padding-top: 20px;
        }
      }
    }
    .card {
      &-header {
        font-weight: 500;
        &-wrap {
          border-bottom: 1px solid #ECF0F2;
        }
      }
    }
  }
  .table {
    &-second {
      width: 100%;
      th, td {
        padding-left: 10px;
      }
      tr {
        td {
          &.card-body {
            padding: 20px !important;
          }
        }
        &:nth-of-type(2){
          td {
            // padding-top: 20px !important;
          }
        }
        th {
          &.card-header {
            padding: 12px 20px;
          }
        }
      }

      .card {
        &-header {
          padding: 12px 0px;
          text-align: left;
          &-wrap {
            // border-bottom: 1px solid #ECF0F2;
            background-color: #F8F9FB;
          }
        }
        &-body {
          line-height: 1;
          padding: 20px ;
          &:last-of-type {
            text-align: right;
          }
          &:nth-of-type(4) {
            .form-select-wrap {
              width: 70px;
            }
          }
          &:nth-of-type(5) {
            .form-select-wrap {
              width: 180px;
            }
          }
          label {
            display: none;
          }
        }
        &-contents-wrap {
          border-top: 1px solid #ECF0F2;
        }
        &-checkbox {
          padding: 20px;
          width: 20px;
          height: 20px;
          input {
            width: 20px;
            height: 20px;
            &[type=checkbox] {
              transform: scale(1);
            }
          }
        }
      }
    }
    &-third {
      width: 100%;
      th, td {
        padding-left: 10px;
      }
      tr {
        td {
          padding-top: 40px;
          padding-bottom: 0;
        }
        &:nth-of-type(2){
          td {
            padding-top: 20px;
          }
        }
      }
      .card {
        &-header {
          width: calc((100% - 60px) / 6);
          padding: 12px 20px 12px 0px;
          text-align: left;
          font-weight: 500;
          &-wrap {
            // border-bottom: 1px solid #ECF0F2;
            background-color: #F8F9FB;
          }
        }
        &-body {
          line-height: 1;
          padding: 20px 20px 20px 0 !important;
          width: calc((100% - 60px) / 6);

        }
        &-contents-wrap {
          border-top: 1px solid #ECF0F2;
        }
        &-checkbox {
          padding: 20px;
          width: 20px;
          height: 20px;
          input {
            width: 20px;
            height: 20px;
            &[type=checkbox] {
              transform: scale(1);
            }
          }
        }
      }
    }
    &-fourth {
      border-radius: 4px;
      background-color: #fff;
      margin-top: 16px;
      margin-bottom: 0;
      thead {
        tr {
          th {
            border: 1px solid #CED4DA;
            font-weight: 500;
          }
        }
      }
      tbody {
        tr {
          td {
            &:nth-of-type(2) {
              width: 108px;
              a {
                width: 83px;
                height: 38px;
              }
            }
          }
        }
      }
      td {
        border: 1px solid #CED4DA;
        line-height: 1;
        vertical-align: unset;
      }
    }
  }
}

@include ipad {
  .def-container {
    .noborder-table {
      th {
        font-size: 14px;
        padding: 12px 8px;
        &.short {
          width: 50px;
        }
      }
      td {
        padding: 12px 8px;
        font-size: 14px;
        &.short {
          width: 50px;
          word-break: break-word;
        }
      }
    }

    .assist-detail-table {
      display: block;
      tbody {
        display: block;
      }
      th, td {
        padding-left: 10px;
      }
      tr {
        display: block;
        border: 1px solid #DEE2E6;
        border-radius: 4px;
        padding: 10px;
        margin-top: 10px;
        width: 100%;
        td {
          padding: 0 !important;
          display: flex;
          width: 100%;
          font-size: 12px;
          & + td {
            margin-top: 8px;
          }
          span {
            display: block;
            white-space: nowrap;
          }
          &.max-column {
            max-width: 100%;
          }
        }
        &:nth-of-type(2){
          // margin-top: 0;
          td {
            padding-top: 0px;
          }
        }
      }
      .card {
        &-header {
          &-wrap {
            display: none;
          }
        }
      }
    }
    .table {
      &-second {
        display: block;
        tbody {
          display: block;
        }
        th, td {
          padding-left: 10px;
        }
        tr {
          display: block;
          border: 1px solid #DEE2E6;
          border-radius: 4px;
          padding: 10px;
          margin-top: 20px;
          width: 100%;
          td {
            &.card-body {
              padding: 0 !important;
            }
            display: flex;
            width: 100%;
            font-size: 12px;
            & + td {
              margin-top: 8px;
            }
            span {
              display: block;
              white-space: nowrap;
            }
            &.max-column {
              max-width: 100%;
            }
            div {
              display: flex;
            }
          }
          &:nth-of-type(2){
            margin-top: 0;
            td {
              padding-top: 0px;
            }
          }
        }
        .card-header-wrap {
          display: none;
        }
        .card-contents-wrap {
          position: relative;
          background-color: #F8F9FB;
          button {
            position: absolute;
            top: 0px;
            right: 0;
          }
          &.checkbox {
            .card-checkbox {
              position: absolute;
              top: 14px;
              left: 10px;
            }
            td {
              padding-left: 30px !important;
              &:first-of-type {
                padding-left: 0 !important;
              }
            }
          }
        }

        .card {
          &-body {
            &.table-body-form {
              flex-direction: column;
            }
            label {
              display: block;
              width: 100%;
            }
            .form-control {
              height: 30px;
              font-size: 12px;
            }
            &:nth-of-type(4) {
              .form-select-wrap {
                width: 100%;
              }
            }
            &:nth-of-type(5) {
              .form-select-wrap {
                width: 100%;
              }
            }
            &:not(:has(span)) {
              display: none;
            }
            &:has(label) {
              display: block;
            }
            &:has(button) {
              display: block;
              margin-top: 0;
            }
          }
          &-checkbox {
            padding: 0;
          }
        }
      }
      &-third {
        display: block;
        tbody {
          display: block;
        }
        th, td {
          padding-left: 10px;
        }
        tr {
          display: block;
          border: 1px solid #DEE2E6;
          border-radius: 4px;
          padding: 10px;
          margin-top: 10px;
          width: 100%;
          td {
            padding: 0 !important;
            display: flex;
            width: auto;
            font-size: 12px;
            & + td {
              margin-top: 8px;
            }
            span {
              display: block;
              white-space: nowrap;
            }
            &.max-column {
              max-width: 100%;
            }
            div {
              display: flex;
            }
          }
          &:nth-of-type(2){
            margin-top: 0;
            td {
              padding-top: 0px;
            }
          }
        }
        .card-header-wrap {
          display: none;
        }
        .card-contents-wrap {
          position: relative;
          padding-bottom: 0;
          button {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
        .card-body {
          margin-left: 30px;
          transform: translateY(-22px);
          width: 100%;
          padding: 0 !important;
        }
      }
    }
  }
}
