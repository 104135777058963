/* ==============================
  module/form
================================= */
@use "../base" as *;
@use "sass:map";

.def-container {
  .card {
    &-container {
      background-color: #ECF0F2 !important;
      box-shadow: none;
      margin-bottom: 0;
      &-cancellation {
        background-color: transparent !important;
      }
      &.white {
        background-color: #fff !important;
      }
    }
    &-accordion {
      &-container {
        background-color: #fff !important;
        box-shadow: none;
        margin-bottom: 0;
        border: 1px solid #CED4DA;
        margin-top: 24px;
      }
      &-area {
        background-color: #fff !important;
        box-shadow: none;
        margin-bottom: 0;
        border: 1px solid #CED4DA;
        margin: 20px 36px 0;
        .card-header {
          border-bottom: 1px solid #CED4DA;
        }
        .zeus-card-title {
          span {
            color: #495057;
            padding-left: 20px;
          }
        }
        .card-body {
          padding: 20px 20px 26px;
          background-color: #fff;
        }
      }
    }
    &-wrap {
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #CED4DA;
      &.no-border {
        border: none;
        border-radius: 0;
      }
    }
    &-header {
      border-bottom: none;
      box-shadow: 0 3px 6px #CED4DA29;
    }
    &-title {
      font-size: 20px;
      font-weight: 600;
      &.small {
        font-size: 18px;
      }
    }
    &-inner {
      padding: 20px 20px 26px;
      box-shadow: 0 3px 6px #CED4DA29;
      &.no-pd {
        padding: 0;
      }
      &-header {
        width: 100%;
        display: inline-block;
        padding: 13px 0 13px 20px;
        border-bottom: 1px solid #CED4DA;
        &.gray {
          background-color: #F8F9FB;
        }
        
      }
    }
    &-space {
      margin-top: 24px;
    }
    &-link-txt  {
      display: inline-block;
      margin-top: 26px;
    }

    &-contents-wrap {
      td {
        span {
          display: none;
        }
      }
    }

    .acco-inner-acco {
      box-shadow: none;
      border: 1px solid #CED4DA;
      &.collapsed-card {
        border-bottom: none;
      }
      .card-header {
        box-shadow: none;
        height: 44px;
        line-height: 1;
        .zeus-card-title {
          font-size: 16px;
        }
      }
    }
  }

  .collapsed-card {
    border-bottom: none;
  }

  .card-wrap .card-wrap .card-inner-header .zeus-card-title {
    font-size: 16px;
  }
}

@include ipad {
  .def-container {
    .card {
      &-container {
        box-shadow: none;
        margin-bottom: 0;
      }
      &-wrap {
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid #CED4DA;
      }
      &-accordion {
        &-area {
          background-color: #fff !important;
          box-shadow: none;
          margin-bottom: 0;
          border: 1px solid #CED4DA;
          margin: 20px 10px 0;
          .card-header {
            padding: 14px 20px;
          }
          .zeus-card-title {
            font-size: 16px;
            span {
              display: block;
              font-size: 20px;
              padding-left: 0;
            }
          }
        }
      }
      &-header {
        border-bottom: none;
        padding-top: 0;
        padding-left: 10px;
      }
      &-title {
        font-size: 20px;
        font-weight: 600;
      }
      &-inner {
        padding: 20px 20px 26px;
        &-header {
          width: 100%;
          display: inline-block;
          padding: 13px 0 13px 20px;
          border-bottom: 1px solid #CED4DA;
        }
      }
      &-space {
        margin-top: 24px;
      }
      &-link-txt  {
        display: inline-block;
        margin-top: 26px;
      }
      &-contents-wrap {
        td {
          span {
            display: inline-block;
          }
        }
      }
    }
  }
}