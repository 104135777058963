/* ==============================
  module/help
================================= */
@use "../base" as *;
@use "sass:map";

.def-container {
  &.help-container {
    padding-bottom: 40px;
  }
  .help {
    &-accordion {
      &-container {
        margin: 16px 37px 0;
        box-shadow: none;
        &:first-child {
          margin-top: 0px;
        }
        &.collapsed-card {
          .card-header {
            border-bottom: none;
          }
        }
        .card {
          &-header {
            height: 55px;
            border-bottom: 1px solid #CED4DA;
            box-shadow: none;
            padding: 14px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .card-title {
              width: calc(100% - 14px);
            }
            &.open {
              background-color: #1EB6C3;
              color: #fff;
              .btn-tool {
                color: #fff;
              }
              .help-inner-title {
                &::before {
                  background-image: url(../images/question_white.svg);
                }
              }
            }
          }
          &-body {
            padding: 16px 10px;
          }
        }
      }
      &-inner {
        &-acco {
          border: 1px solid #CED4DA;
          border-radius: 4px;
          box-shadow: none;
          margin-bottom: 0;
          margin-top: 16px;
          &:first-of-type {
            margin-top: 0;
          }
          .card {
            &-header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: calc(100% - 14px);
              padding: 0 20px;
              background-color: #F8F9FB;
              transition: 0.2s;
              .card-tools {
                .btn-tool {
                  margin: 0;
                  padding: 0;
                }
              }
            }
          }
          &.collapsed-card {
            .card {
              &-header {
                border-bottom: none;
                border-radius: 4px;
              }
            }
          }
        }
      }
    }
    &-inner {
      &-title {
        position: relative;
        font-size: 16px;
        margin-bottom: 0;
        width: calc(100% - 14px);
        padding-left: 38px;
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          display: inline-block;
          width: 28px;
          height: 28px;
          background-image: url(../images/question_blue.svg);
          background-repeat: no-repeat;
          background-size: cover;
          transition: 0.2s;
        }
      }
      &-img {
          width: 100%;
          height: auto;
          &-area {
            max-width: 315px;
            width: 100%;
            margin-bottom: 10px;
          }
        }
        &-txt {
          font-size: 16px;
          margin-bottom: 0;
          span {
            color: #FF0000;
          }
        }
    }
  }
  .external-link {
    display: flex;
    align-items: center;
    &::after {
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      background-image: url(../images/external_link.svg);
      background-repeat: no-repeat;
      background-size: cover;
      margin-left: 5px;
      margin-top: 2px;
    }
  }
  .download-link {
    display: flex;
    align-items: center;
    &::after {
      content: "";
      display: inline-block;
      width: 14px;
      height: 15px;
      background-image: url(../images/download_link.svg);
      background-repeat: no-repeat;
      background-size: cover;
      margin-left: 5px;
      margin-top: 2px;
    }
  }
}

@include ipad {
  .def-container {
    .help {
      &-accordion {
        &-container {
          margin: 16px 10px 0;
          &:first-child {
            margin-top: 0px;
          }
          .card {
            &-header {
              padding: 15px 20px;
            }
            &-body {
              padding: 16px 10px;
            }
          }
        }
        &-inner {
          &-acco {
            .card {
              &-header {
                padding: 10px;
                height: auto;
              }
            }
          }
        }
      }
      &-inner {
        &-img {
          &-area {
            max-width: 70%;
            width: 100%;
            margin: 0 auto;
          }
        }
      }
    }
  }
}