/* ==============================
  layout/footer
================================= */
@use "../base" as *;
@use "sass:map";

.def-container {
  .footer {
    &-btn {
      &-area {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        background-color: #DEE2E6;
        padding: 32px 0;
      
      }
    }
  }
}

@include ipad {
  .def-container {
    .footer {
      &-btn {
        &-area {
          width: 100%;
        }
      }
    }
  }
}
