/* ==============================
  layout/layout
================================= */
@use "../base" as *;

.l {
  &-header {    
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 80px;
    @include pc {
      height: 60px;
    }
  }
  &-main {
    margin-top: 80px;
    @include pc {
      margin-top: 60px;
    }
    &-inner {
      padding-top: 68px;
      @include ipad {
        padding-top: 50px;
      }
    }
  }
}
