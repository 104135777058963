@charset "utf-8";
/* ==============================
  base/mixin
================================= */
@use "variables" as var;

//font-family
@mixin font-ibm{
  font-family: 'IBM Plex Sans', sans-serif;
}

//font-sizeとline-height計算
@mixin font($fontsize, $lineheight) {
  $linS: calc($lineheight / $fontsize);
  font-size: #{$fontsize}px;
  line-height: $linS;
}
//letter-spacing
@mixin letter($letterSpacing) {
  $result: $letterSpacing / 1000;
  letter-spacing: #{$result}em;
}


//flex set
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin sp {
  @media (max-width: (var.$sp)) {
    @content;
  }
}
@mixin ipad {
  @media (max-width: (var.$ipad)) {
    @content;
  }
}
@mixin pc {
  @media (max-width: (var.$pc)) {
    @content;
  }
}


//  hack
@mixin hack($IE-ver: null) {
  @if $IE-ver=='Edge' {

    // IEdge
    @supports (-ms-ime-align: auto) {
      @content;
    }
  }
}

//icon
@mixin iconSet {
  [class^="icon-"],
  [class*=" icon-"] {
    @content;
  }
}