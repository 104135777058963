/* ==============================
  module/form
================================= */
@use "../base" as *;
@use "sass:map";

.def-container {
  font-weight: 500;
  .is-sp {
    display: none;
  }
  .mt8 {
    margin-top: 8px;
  }
  .mt16 {
    margin-top: 16px;
  }
  .mt20 {
    margin-top: 20px;
  }
  .mt24 {
    margin-top: 24px;
  }
  .mt40 {
    margin-top: 40px;
  }
  .pt8 {
    padding-top: 8px !important;
  }
  .pb0-26 {
    padding-bottom: 0;
  }

  .f-semibold {
    font-weight: 600;
  }

  .content {
    padding-top: 24px;
    background-color: #ECF0F2;
    margin-top: 0;
    &-header {
      padding: 0;
    }
  }

  .construction-check {
    &-container {
      background-color: #fff !important;
      margin-top: -24px;
      border-radius: 0;
      .heading-5 {
        margin-top: 21px;
        &:first-of-type {
          margin-top: 21px;
        }
      }
      .card-body.assist-detail {
        padding: 10px 37px 0;
        & > dl > .heading-5 {
          margin-top: 26px;
        }
      }
    }
    &-header {
      margin: 24px 37px 0;
    }
  }

  .assist-detail {
    dt {
      background-color: #ECF0F2;
      padding: 4px 10px;
    }
    dd {
      padding: 8px 10px 24px;
      min-height: 56px;
    }
  }

  .zeus-help {
    font-size: 12px;
    &::before {
      margin-right: 4px;
    }
    &-left {
      float: none;
      margin-left: -40px;
    }
  }

  .badge {
    vertical-align: text-bottom;
    transform: translateY(-2px);
    &-any {
      background-color: #DEE2E6;
      color: #6C757D;
      width: 47px;
    }
    &-success {
      padding-left: 8px;
      padding-right: 8px;
    }
    &-secondary {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .construction-history-area {
    background-color: #fff;
    padding-top: 16px;
    padding-bottom: 36px;
    .zeus-card-title {
      background-color: #fff;
      margin-bottom: 0;
      font-size: 16px;
    }
    .card-body {
      .card-body {
        padding: 0;
      }
    }
  }

  .alert-caution-zeus {
    background-color: #FFFBF7;
    border: 1px solid #FD7E14;
    color: #FD7E14;
    border-radius: 4px;
    padding: 16px;
    font-size: 14px;
    margin-bottom: 24px;
  }

  .chart-container {
    max-width: 100%;
    align-self: auto;
    .alert-warning-zeus {
      vertical-align: unset;
      margin: 20px;
      p {
        margin-bottom: 0;
      }
    }
  }

  .data-serch {
    display: flex;
    align-items: center;
    p {
      font-size: 14px;
      color: #1EB6C3;
      margin-left: 12px;
      margin-bottom: 0;
    }
  }
}

@include ipad {
  .def-container {
    .is-sp {
      display: block;
    }
    .pb0-26 {
      padding-bottom: 26px;
    }

    .zeus-help {
      float:none;
    }

    .construction-check {
      &-container {
        .heading-5 {
          margin-top: 40px;
          &:first-of-type {
            margin-top: 40px;
          }
        }
        .card-body.assist-detail {
          padding: 10px 10px 0;
        }
      }
      &-header {
        margin: 24px 10px 0;
      }
    }
  }
}