/* ==============================
  module/form
================================= */
@use "../base" as *;
@use "sass:map";
.def-container {
  min-height: 100vh !important;
  background-color: #ECF0F2 !important;
  dl {
    margin-bottom: 0;
  }
  dd {
    margin-bottom: 0;
    font-size: 14px;
  }
  dt {
    font-weight: 600;
  }
  label {
    margin-bottom: 0;
  }

  .form-control {
    box-shadow: none;
  }
  .zeus-card-title {
    font-weight: 500;
    font-size: 18px;
    &-note {
      display: block;
      font-size: 12px;
      color: #969BA1;
      padding-top: 4px;
    }
  }
  .container-fluid {
    padding: 0;
  }
  .content {
    padding: 24px 0 0;
    margin-top: 0px;
  }
  .card-inner {
    .card-body {
      padding: 0 !important;
    }
  }
  .card-body {
    padding: 16px 36px 40px ;
    &.assist-detail {
      padding: 10px 20px 20px;
      &.short-pb {
        padding: 10px 20px 40px;
      }
    }
  }
  .alert-warning-zeus {
    color: #212529;
    background: #FFF3CD;
    border: none;
    font-size: 14px;
    font-weight: normal;
    margin-top: 12px;
    margin-bottom: 0;
    padding: 8px 16px;
    line-height: 1.5;
  }
  label:not(.form-check-label):not(.custom-file-label) {
    font-weight: 500;
  }
  .def-container label:not(.form-check-label):not(.custom-file-label) {
    font-weight: 500;
  }
}

.btn-export-csv {
  width: 200px;
}

.full-width {
  width: 100%;
}

.small-column {
  width: 50px;
}

.medium-column {
  width: 100px;
}

.large-column {
  width: 150px;
}

.max-column {
  max-width: 160px;
}

.center-modal {
  top: 30%;
}

.center-modal .modal-dialog{
  margin-top: 30%;
}

.min-max-container {
  min-width: 900px;
  max-width: 1500px;
}

.content {
  margin-top: 34px;
}

.table-container {
  background: #f9f9f9; border: 1px solid #e9e9e9;
}

.min-container {
  min-width: 600px;
}

.max-container {
  max-width: 1200px;
}

.check-header {
  position: absolute;
  font-size: large;
}

.alert-warning-zeus {
  color: #1f2d3d;
  background: #fff3cd;
  border-color: #edb100;
}

.alert-warning-zeus a {
  color: #007bff;
  text-decoration: underline;
}

.alert-danger-zeus {
  color: #fff;
  background: #dc3545;
  border-color: #d32535;
}

.alert-caution-zeus a {
  color: #fff;
  text-decoration: underline;
}
.alert-caution-zeus {
  color: #fff;
  background: #F77647;
  border-color: #DE6A3F;
}

.alert-danger-zeus a {
  color: #fff;
  text-decoration: underline;
}

.alert-success-zeus {
  color: black;
  background: #d4edda;
  border-color: #23923d;
}

.alert-success-zeus a {
  color: #007bff;
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.zeus-help {
  float: right;
  color: #1EB6C3;
  margin-top: 5px;
  cursor: pointer;
}

.card {
  background-color: #fafafa !important;
}

.main-header.navbar {
  background-color: #fafafa !important;
}

.form-control {
  box-shadow: 0.3px 0.3px 0.3px darkgrey;
}

.form-control:focus {
  border-color:#1EB6C3 !important;
}

.form-check {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 40px;
}

.navbar {
  position: fixed;
  left: 0;
  right: 0;
}

.main-sidebar {
  position: fixed !important;
}

.nav-title > span {
  vertical-align: middle;
  font-size: x-large;
}

.nav-profile>.user-profile{
  pointer-events: none;
}

.badge {
  vertical-align: text-top;
  margin-left: 10px;
}

.zeus-card-title {
  font-weight: bold;
  font-size: large;
}

.main-sidebar {
  background-color: white;
}

.main-sidebar div:first-of-type > nav > ul > li > a > i{
  color: white;
}

.main-sidebar div:first-of-type > nav > ul > li > a > p {
  color: white;
}

.main-sidebar div:first-of-type {
  padding-bottom: 30px;
}

.main-sidebar div:not(:first-of-type) > nav > ul > li:hover{
  background-color: rgba(0, 0, 0, 0.1);
}

.main-sidebar div:not(:first-of-type) > nav > ul > li > .active{
  background-color: #1EB6C3 !important;
}

.main-sidebar div:not(:first-of-type) > nav > ul > li > a > p {
  color: black;
}

.main-sidebar div:not(:first-of-type) > nav > ul > li > .active > p {
  color: white;
}

.zeus-brand-text {
color: white;
font-size: xx-large;
padding-left: 20px;
}

.brand-link {
  border-width: 0 !important;
}

.zeus-cloud-left {
  color: white;
  font-size: 6em;
  position: absolute;
  transform: translate(-20px,30px);
}

.zeus-cloud-right {
  color: white;
  font-size: 9em;
  position: absolute;
  transform: translate(80px,-10px);
}

.zeus-bold {
  position: absolute;
  transform: translate(170px, -110px) rotate(30deg) scale(1, 3);
  color: yellow;
  font-size: 70px;
}

.main-sidebar {
  overflow-x: hidden;
}

.main-sidebar > a {
  position: relative;
}

.btn-done {
  background-color: #1EB6C3;
  color: white;
}

.btn-done:hover {
  background-color: rgba(30, 182, 195, 0.7);
  color: white;
}

.btn-confirm {
  background-color: #F77647;
  color: white;
}

.btn-confirm:hover {
  background-color: rgba(247, 118, 71, 0.7);
  color: white;
}

.card-header > span {
  margin-right: 20px;
}

body {
  font-family: Noto Sans JP;
}

.login-form {
  border-right: 1px solid #ced4da !important;
}

.chart-container {
  max-width: 600px;
  align-self: center;
}

input[type=radio],
input[type=checkbox]
{
-ms-transform: scale(1.5); /* IE */
-moz-transform: scale(1.5); /* FF */
-webkit-transform: scale(1.5); /* Safari and Chrome */
-o-transform: scale(1.5); /* Opera */
-webkit-transform-origin: right;
}

.form-check-input {
  margin-top: .5rem !important;
  margin-left: -1.5rem !important;
}

.error-section {
  transform: translate(50%, -50%);
  top: 40%;
  position: absolute;
}

.error-zeus {
  padding-top: 10px;
}

.canvas_wrapper{
margin:0 auto;
}

.left-box {
  display:inline-block;
  width:80%;
  height:40px;
}

.right-box {
  display:inline-block;
  float:right;
  width:20%;
  height:40px;
  text-align:right;
}

@media screen and (max-width: 600px) {
  .content {
      padding-left: 0 !important;
      padding-right: 0 !important;
  }

  .container-fluid {
      padding-left: 0 !important;
      padding-right: 0 !important;
  }

  .card-body {
      padding-left: 10px !important;
      padding-right: 10px !important;
  }

  .check-header > .custom-control-label {
      margin-left: 30px;
      margin-right: 20px;
  }

  .check-header {
      display: contents;
  }
  .card-header {
      display: flex;
  }

  .card-tools {
      position: absolute;
      right: 20px;
  }

  .custom-control-label::before {
      left: -30px;
  }

  .custom-control-label::after {
      left: -30px;
  }

  .min-max-container {
      min-width: 300px;
  }
}

.non-margin {
  margin: 0, 0, 0, 0;
}

[v-cloak] { display: none;}

@include ipad {
  .elevation-4 {
    box-shadow: none !important;
  }
  .def-container {
    .card-body {
      padding: 16px 10px 40px;
      &.assist-detail {
        padding: 10px 20px 20px;
        &.short-pb {
          padding: 10px 20px 40px;
        }
      }
    }
  }
}