/* ==============================
  module/btn
================================= */
@use "../base" as *;
@use "sass:map";

.def-container {
  .btn {
    transition: 0.4s;
    &:hover {
      opacity: 0.65;
    }
    &-wrap {
      margin-top: 26px;
      text-align: center;
    }
    &-second {
      border: 1px solid #1EB6C3;
      color: #1EB6C3;
      background-color: #fff;
      font-size: 16px;
      padding: 7px 13px;
    }
    &-tertiary {
      max-width: 300px;
      width: 100%;
      height: 46px;
      border-radius: 4px;
      background-color: #1EB6C3;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      opacity: 1;
    }
    &-quaternary {
      background-color: transparent;
      color: #1EB6C3;
      font-size: 12px;
      font-weight: normal;
    }
    &-search {
      max-width: 315px;
      width: 100%;
      height: 44px;
      font-size: 16px;
      color: #fff;
      background-color: #1EB6C3;
      margin: 26px auto 0;
    }
    &-none {
      background-color: #9EA2A5;
      font-weight: 500;
    }

    &-short {
      max-width: 170px;
      width: 100%;
      line-height: 1;
      height: 44px;
    }

    &-shorter {
      max-width: 138px;
      height: 38px;
    }

    &-back {
      color: #212529;
      font-size: 16px;
      opacity: 1;
      margin-top: 20px;
      font-weight: 500;
      padding: 0;
      border: none;
    }
  }
  
  .badge {
    font-size: 12px;
  }
}


@include ipad {
  .def-container {
    .btn {
      &:hover {
        opacity: 1;
      }
      &-search {
        margin-top: 20px;
      }
    }
  }
}