/* ==============================
  module/form
================================= */
@use "../base" as *;
@use "sass:map";

.def-container {
  .heading {
    &-1 {
      padding: 0 0 0 36px;
      font-weight: 600;
      box-shadow: none;
    }
    &-2 {
      font-size: 18px;
      font-weight: 600;
    }
    &-3 {
      font-size: 16px;
    }
    &-4 {
      font-size: 20px;
      padding: 0 0 12px 0;
    }
    &-5 {
      font-size: 18px;
      font-weight: 600;
      border-left: 4px solid #1EB6C3;
      padding-left: 8px;
      margin-bottom: 17px;
      margin-top: 16px;
      &:first-of-type {
        margin-top: 0px;
      }
    }
    &-6 {
      display: block;
      font-size: 18px;
      font-weight: 600;
      border-bottom: 1px solid #CED4DA;
      padding: 12px 0px;
      margin-bottom: 24px;
    }
  }

  .help-txt {
    display: block;
    font-size: 12px;
    color: #1EB6C3;
    margin-top: 8px;
    margin-left: -28px;
    &:first-of-type {
      margin-top: 0;
    }
  }

  .modal-link-txt {
    display: block;
    width: 100%;
    font-size: 14px;
    color: #007BFF;
    margin-top: 12px;
  }

  .page-top-link {
    font-size: 14px;
    padding-left: 36px;
    margin-bottom: 8px;
    font-weight: 500;
    color: #212529;
    a {
      color: #007BFF;
    }
  }

  .zeus-card-form-title {
    font-size: 16px;
  }

  .card-title {
    float: none;
  }
}

@include ipad {
  .def-container {
    .heading {
      &-1 {
        padding: 0 0 0 10px;
      }
    }
    .page-top-link {
      font-size: 14px;
      padding-left: 10px;
      font-weight: 500;
      color: #212529;
      a {
        color: #007BFF;
      }
    }
    .help-txt {
      margin-left: -32px;
    }
  }
}