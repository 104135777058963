/* ==============================
  module/form
================================= */
@use "../base" as *;
@use "sass:map";

.def-container {
  .form {
    &-group {
      margin-bottom: 0;
      margin-top: 0px;
      & + .form-group {
        margin-top: 20px;
      }
      & + {
        div {
          margin-top: 20px;
        }
      }
      &.form-btn-group {
        margin-top: 26px;
      }
      
    }
    &-check {
      padding-top: 0px;
      padding-bottom: 0;
      padding-left: 30px;
      margin-top: 10px;
      &-label {
        position: relative;
        span {
          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: -30px;
            transform: translateY(-50%);
            display: block;
            width: 20px;
            height: 20px;
            border: 1px solid #707070;
            border-radius: 999px;
          }
        }
        dl {
          &::before {
            content: "";
            position: absolute;
            top: 4px;
            left: -30px;
            // transform: translateY(-50%);
            display: block;
            width: 20px;
            height: 20px;
            border: 1px solid #707070;
            border-radius: 999px;
          }
        }
      }
      &-input {
        &.require.check {
          & + span::before {
            background-color: #fff;
          }
        }
      }
    }
    &-control {
      appearance: none;
      margin-top: 4px;
    }
    &-wrap {
      margin-top: 4px;
    }
    &-select-wrap {
      position: relative;
      margin-top: 4px;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%) rotate(45deg);
        display: block;
        width: 6px;
        height: 6px;
        border-bottom: 1px solid #4F5054;
        border-right: 1px solid #4F5054;
      }
    }
    &-area {
      margin-top: 0;
      & + .form-area {
        margin-top: 20px;
      }
    }
    &-serch {
      background-color: #F3FAFB;
    }
  }

  .input {
    &-area {
    margin-top: 4px;
    }
  }

  input {
    &[type="text"],
    &[type="tel"] {
      &:valid {
        background-color: #fff;
      }
      &:invalid {
        background-color: #F3FAFB;
      }
    }
    &[type="radio"] {
      appearance: none;
      transform: scale(1);
      &.require + span {
        &::before {
          background-color: #F3FAFB;
        }
      }
      &:checked {
        & + span {
          &::before {
            border-color: #007BFF;
          }
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: -25px;
            transform: translateY(-50%);
            display: block;
            width: 10px;
            height: 10px;
            background-color: #007BFF;
            border-radius: 999px;
          }
        }
        & + dl {
          &::before {
            border-color: #007BFF;
          }
          &::after {
            content: "";
            position: absolute;
            top: 9px;
            left: -25px;
            display: block;
            width: 10px;
            height: 10px;
            background-color: #007BFF;
            border-radius: 999px;
          }
        }
      }
    }
  }

  select {
    &:valid {
      background-color: #fff;
    }
    &:invalid {
      background-color: #F3FAFB;
    }
  }

  .auto-select-input {
    max-width: 355px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%) rotate(45deg);
      display: block;
      width: 6px;
      height: 6px;
      border-bottom: 1px solid #fff;
      border-right: 1px solid #fff;
    }
    &-wrap {
      display: flex;
      flex-direction: column;
      border: none;
      margin-bottom: 30px;
      &.check {
        .modal-link-txt {
          display: block;
        }
        select {
          background-color: #D6EAFF;
          border: 1px solid #007BFF;
          color: #007BFF;
        }
        .auto-select-input {
          &::after {
            transform: translateY(-50%) rotate(-45deg);
            border-bottom: 1px solid #007BFF;
            border-right: 1px solid #007BFF;
          }
        }
      }
      .modal-link-txt {
        display: none;
      }
    }
    select {
      border: none;
      background-color: transparent;
      color: #fff;
      background-color: #007BFF;
      border: 1px solid #007BFF;
      appearance: none;
      width: 100%;
      height: 32px;
      padding: 0px 20px;
      outline: none;
      border-radius: 4px;
      font-size: 14px;
    }
  }
}

@include ipad {
  .def-container {
    .form {
      &-check {
        padding-left: 32px;
        & + .form-check {
          margin-top: 10px;
        }
      }
    }
  }
}